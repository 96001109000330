import {useField, UseFieldConfig} from 'react-final-form'

import CheckboxInput from '../CheckboxInput'
import FieldError from './FieldError'
import {Flex, Box} from '../Box'

interface Props extends UseFieldConfig<string> {
  name: string
  id?: string
  disabled?: boolean
  type?: 'checkbox'
  renderLabel?: () => React.ReactNode
}

const CheckboxField = ({
  name,
  id,
  type = 'checkbox',
  renderLabel,
  disabled = false,
  ...config
}: Props) => {
  const {input} = useField<string, HTMLInputElement>(name, {
    type,
    ...config,
  })

  return (
    <Flex flexDirection="column">
      <Flex
        css={{
          // nudge the checkbox up to better align with text
          '> label': {
            top: -7,
          },
        }}
      >
        <CheckboxInput
          {...input}
          id={id ? id : name ? name : undefined}
          type={type}
          disabled={disabled}
        />
        {renderLabel && (
          <Box ml="2" css={{flex: '1'}}>
            {renderLabel()}
          </Box>
        )}
      </Flex>
      <FieldError name={'agree_terms'} />
    </Flex>
  )
}

export default CheckboxField
