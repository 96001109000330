import React from 'react'
import {css, SerializedStyles} from '@emotion/react'

import {GoogleSso} from './icons'
import {Flex} from './Box'

interface GoogleSsoButtonProps {
  buttonText: string
  redirectLink: string
}

const buttonStyles: SerializedStyles = css`
  background-color: #fff;
  color: #465169;
  border: 3px solid #7f879d;
  border-radius: 100px;
  padding: 8px 16px;
  font-weight: 600;
  line-height: 100%;
  cursor: pointer;
  min-width: 300px;
`

const GoogleSsoButton: React.FC<GoogleSsoButtonProps> = ({
  buttonText,
  redirectLink,
}) => {
  const handleSignIn = () => {
    window.location.href = redirectLink
  }

  return (
    <button css={buttonStyles} onClick={handleSignIn}>
      <Flex
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Flex mr={1}>
          <GoogleSso />
        </Flex>
        <Flex style={{fontWeight: 700, fontSize: '1.15rem', lineHeight: 1}}>
          {buttonText}
        </Flex>
      </Flex>
    </button>
  )
}

export default GoogleSsoButton
