import {useStaticQuery, graphql} from 'gatsby'
import {StateOptionsQuery} from 'gatsby-graphql-types-generated'

import {StateOption} from 'src/graphql-generated'

const useStateOptions = (): Pick<StateOption, 'code' | 'name'>[] => {
  const {
    cms: {states},
  } = useStaticQuery<StateOptionsQuery>(graphql`
    query StateOptions {
      cms {
        states {
          code
          name
        }
      }
    }
  `)

  return states as any
}

export default useStateOptions
