import useStateOptions from 'src/hooks/useStateOptions'
import SelectField, {
  Props as SelectFieldProps,
} from 'src/components/form/SelectField'
import * as breakpoints from 'src/styles/mediaQuery'

type Props = Omit<SelectFieldProps, 'options'>

const StateSelect = (props: Props) => {
  const states = useStateOptions()

  const options = states.map((s) => ({
    value: s.code,
    name: s.name,
  }))

  return (
    <SelectField
      {...props}
      options={options}
      css={{
        paddingBottom: '0',
        [breakpoints.medium]: {
          paddingBottom: '1rem',
        },
      }}
    />
  )
}

export default StateSelect
